<template>
  <!-- 导入图片 -->
  <div class="addPerson__wrap" v-show="visible" v-loading="loading"  element-loading-text="图片上传中">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          批量导入图片
        </div>
      </div>
    </el-header>
    
    <div class="main">
      <div class="mainBox">
        <div class="itemTime">商品信息</div>
        <div class="info-text">1.本次上传图片，一个商品的图片最多上传6张，若超过6张，则不会成功排序6之后的图片，若上传图片加上系统本身存在图片，超过六张，则上传图片优先，超出部分从原有图片中随机删除</div>
        <div class="info-text">2.使用商品名称命名商品图片</div>
        <div class="info-text">3.商品图片命名规则：商品名称_1.JPG、商品名称_2.JPG、商品名称_3.JPG,数字123表示主图的排序</div>
        <div class="info-text">4.单张图不能大于2M,建议图片尺寸800*800，支持JPG,PNG,JPEG</div>

        <div class="itemTime mt30">导入图片<span style="font-size:12px;color:#8a8a8a;"> (按住Ctrl"键批量选择图片，单张图片不大于2M)</span></div>
        
        <div style="margin-left:30px;">

          <el-upload
            ref="upload"
            action=""
            list-type="picture-card"
            :multiple="true"
            :limit="20"
            :auto-upload="false"
            :file-list="imgFileList"
            :on-change="uploadFile"
            :before-upload="beforeAvatarUpload"
            accept="image/jpeg, image/png, image/jpg"
            class="uploadWrap">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}" style="height:100%;">
                <div style="font-size:12px;line-height: 12px;text-align: center;">{{file.name}}</div>
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url" alt="" 
                  style="object-fit: cover;"
                >
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>

              </div>
          </el-upload>
          <el-dialog append-to-body :visible.sync="dialogVisibleImg">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </div>
        <el-button style="margin-top:30px;margin-left:30px;" type="primary" icon="el-icon-upload" @click='submitUpload'>上传</el-button>
      </div>
    </div>

    <el-dialog
      title="导入图片反馈提示"
      :visible.sync="dialogVisible"
      width="500px"
      append-to-body
      :show-close="false"
      :before-close="handleClose">
      <div >
        <div class="mt20">导入成功个数：<span style="font-size:18px;">{{uploadSuccess}}</span></div>
        <!-- no_found:未找到的 -->
        <div v-if="no_found.length > 0">
          <div class="mt20">以下商品系统未找到，请检查后再操作：</div>
          <div class="mt10">
            <span v-for="(item1,index1) in no_found" :key="index1">【{{item1}}】</span>
          </div>
        </div>
        <!-- misname:命名不规范 -->
        <div v-if="misname.length > 0">
          <div class="mt20">以下图片格式有误，请修改命名后再操作：</div>
          <div class="mt10">
            <span v-for="(item2,index2) in misname" :key="index2">【{{item2}}】</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import { getStorage } from '@/storage'
  export default {
    name: 'GoodsListDetail',
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        loading: false,

        dialogImageUrl: '',
        dialogVisibleImg: false,

        dialogVisible: false,
        misname: [], // 命名不规范 
        no_found: [], // 未找到商品
        uploadSuccess: 0, // 成功个数
       
        imgFileList:[],

      }
    },
    methods: {
      getDetail(row) {
        this.curRow = row;
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 图片上传前的回调
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isLt2M) {
          this.$message.warning('上传图片大小不能超过 2MB!');
        }
        return isLt2M;
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisibleImg = true;
      },
      handleRemove(file) {
        console.log(file)
        console.log(this.imgFileList)
        if(this.imgFileList.length > 0) {
          this.imgFileList.forEach((v, i) => {
            if(file.uid === v.uid) {
              this.imgFileList.splice(i,1)
            }
          })
        }
      },
      uploadFile(file) {
        this.imgFileList.push(file)
      },
      submitUpload() {
        if(this.imgFileList.length < 1) {
          return this.$message.warning('请选择图片')
        }
        var formData = new FormData();
        formData.append("token", this.token);
        this.imgFileList.forEach(v => {
          formData.append('file[]',v.raw)
        })
        this.loading = true;
        this.$http.post('/admin/goods/upload', {formData, type:'upload'}).then(res => {
          if(res.code == 1) {
            this.dialogVisible = true;
            this.misname = res.data.misname;  // 命名不规范 
            this.no_found = res.data.no_found;  // 未找到商品
            this.uploadSuccess = res.data.success;  // 成功个数
            this.imgFileList = [];
          }
        }).finally(() => {
          this.loading = false;
          
        })
        
      },
      handleClose() {
        this.dialogVisibleImg = false;
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
      }
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}
.info-text {
  margin-top: 15px;
  font-size: 14px;
  color: #545454;
  line-height: 18px;
  padding: 0 30px;
}
.imgWrap {
  position: relative;
  float: left;
}
.shade-icon {
  width: 100px;
  height: 100px;
  background: #000;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  border-bottom: solid 1px #eee;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.mt30 {
  margin-top: 30px;
}
.mt20 {
  margin-top: 20px;
}
.mt10 {
  margin-top: 10px;
}

</style>
<style scoped>
.uploadWrap ::v-deep .el-upload-list__item {
  width: 100px !important;
  height: 100px !important;
}

.uploadWrap ::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

</style>
